export const addressMasterData = {
    "India": {
        "Maharashtra": ["Mumbai", "Pune", "Nagpur", "Nashik"],
        "Karnataka": ["Bangalore", "Mysore", "Mangalore", "Hubli"],
        "Delhi & NCR": ["Delhi", "Faridabad", "Bhiwani", "Gurgaon", "Karnal", "Ghaziabad", "Noida"],
        "West Bengal": ["Kolkata"],
        "Gujarat": ["Ahmedabad"],
        "Uttar Pradesh": ["Lucknow"],
        "Tamil nadu": ["Chennai"],
        "Telangana": ["Hyderabad"],
        "Rajasthan": ["Jaipur"],
        "Assam": ["Guwahati"]
    }
};
